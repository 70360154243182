//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      entries: [
        {
          name: "下载APP",
          desc: "下载手机版APP免费领茶",
          class: "bg-white text-dark",
          to: "/download",
          img: "/images/home/download.png",
        },
        {
          name: "交易厅",
          desc: "买茶卖茶就上茶友网撮合交易厅",
          class: "bg-white text-dark",
          to: "/trade",
          img: "/images/home/trade.png",
        },
        {
          name: "领好茶",
          desc: "茶友网评测中心百万好茶免费送",
          class: "bg-white text-dark",
          to: "/events",
          img: "/images/home/offercenter.png",
        },
      ],
    };
  },
};
