//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head() {
    return {
      title: "茶友网首页",
    };
  },
};
