import isEmpty from 'lodash/isEmpty'

export default {
  formatPrice(num) {
    if (isEmpty(num)) {
      return '--';
    }

    let moneyUnits = ["元", "万元", "亿元", "万亿"]
    let dividend = 10000
    let curentNum = num //转换数字
    let curentUnit = moneyUnits[0] //转换单位
    for (let i = 0; i < 4; i++) {
      curentUnit = moneyUnits[i]
      if (this.strNumSize(curentNum) < 5) {
        break;
      }
      curentNum = curentNum / dividend
    }
    let price = Number(curentNum).toFixed(1);
    price = parseFloat(price);
    return `${price}${curentUnit}`
  },

  strNumSize(tempNum) {
    let stringNum = tempNum.toString()
    let index = stringNum.indexOf(".")
    let newNum = stringNum
    if (index !== -1) {
      newNum = stringNum.substring(0, index)
    }
    return newNum.length
  }
}