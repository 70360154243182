//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      keywords: "",
      selectedSearchType: "",
      searchSelectionMap: ["找茶", "出茶", "普洱茶网综合"],
      recommendWords: ["中茶", "大益", "陈升号", "下关", "八角亭"],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initSelectDefault();
    });
  },
  methods: {
    initSelectDefault() {
      const { searchSelectionMap } = this;
      this.selectedSearchType = searchSelectionMap[0];
    },
    search() {
      const { keywords, selectedSearchType } = this;
      if (selectedSearchType == "找茶") {
        this.$router.push(`/trade/recent/buy?keywords=${keywords}`);
        return;
      }

      if (selectedSearchType == "出茶") {
        this.$router.push(`/trade/recent/sell?keywords=${keywords}`);
        return;
      }

      if (selectedSearchType == "普洱茶网综合") {
        const win = window.open();
        win.location.href = `https://www.puercn.com/search/?q=${keywords}`;
        return;
      }
    },
  },
};
