//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import format from "~/utils/format";

export default {
  props: {
    trade: Object,
  },
  computed: {
    ltypeText() {
      const { trade } = this;
      return trade.ltype.toString() == "1" ? "Ta要买" : "Ta要卖";
    },
    ltypeClass() {
      const { trade } = this;
      return trade.ltype.toString() == "1" ? "badge-primary" : "badge-danger";
    },
    priceTag() {
      const { trade } = this;
      if (!this.$_.isEmpty(trade.price) && Number(trade.price) != 0) {
        return format.formatPrice(trade.price, 2);
      }

      return "面议";
    },
    priceTagClass() {
      const { trade } = this;
      if (!this.$_.isEmpty(trade.price) && Number(trade.price) != 0) {
        return "text-danger font-weight-bold";
      }

      return "text-dark";
    },
  },
};
