//
//
//
//
//
//
//

import tradeApi from "~/api/trade";

export default {
  name:'trade-home-recommeds',
  mounted() {
    this.$nextTick(async () => {
      await this.getLatest();
    });
  },
  data() {
    return {
      trades: [],
    };
  },
  methods: {
    async getLatest() {
      const rs = await tradeApi.list({
        page: 1,
        ltype: null,
        listRows: 15,
        sort_field: "created_at",
      });

      if (rs) {
        this.trades = rs.data;
      }
    },
  },
};
