import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1ab294ba&lang=pug&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSearch: require('/Users/virs/Chayou/chayou_com/components/home/Search.vue').default,HomeFavEntries: require('/Users/virs/Chayou/chayou_com/components/home/FavEntries.vue').default,TradeHomeRecommeds: require('/Users/virs/Chayou/chayou_com/components/trade/HomeRecommeds.vue').default})
